<template>
  <div>
    <div
      class="text-h6 text-sm-h4 my-4 d-flex justify-space-between align-center"
    >
      Категории продуктов
    </div>
    <v-card
      class="py-2 px-sm-16 py-sm-10"
      style="box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2)"
    >
      <v-dialog
        width="400px"
        v-if="saving"
        :value="saving"
        persistent
        @click:outside="saving = upload_progress >= 100 ? false : true"
      >
        <v-card color="#f0f8f6" class="overflow-hidden">
          <div class="text-right">
            <v-btn
              icon
              @click="
                $store.commit('setStopFlag', true);
                saving = false;
              "
            >
              <v-icon large>mdi-close</v-icon>
            </v-btn>
          </div>
          <v-row justify="center" align="center" class="pb-5">
            <v-col class="text-center text-h5 mt-2">
              <div>Сохранение продукта</div>
            </v-col>
          </v-row>
          <div class="d-flex align-center">
            <v-progress-linear
              v-if="upload_progress != 100"
              :value="upload_progress"
              class="ma-2"
              striped
              height="20"
            >
              <template v-slot:default="{ value }">
                <strong class="white--text">{{ value }}%</strong>
              </template>
            </v-progress-linear>
            <div
              v-if="upload_progress >= 100"
              class="text-center flex-grow-1 pb-2"
            >
              Продукт успешно добавлен
            </div>
          </div>
        </v-card>
      </v-dialog>
      <v-treeview :items="CATEGORIES">
        <template slot="label" slot-scope="{ item }">
          <div class="d-flex align-center">
            <span
              v-text="item.name"
              class="multiline-text"
              style="width: 75%"
            />
            <div class="ml-auto">
              <v-btn
                color="primary"
                class="ml-4"
                @click="createProduct(item.id)"
                v-if="item.children.length === 0"
              >
                <v-icon>mdi-plus</v-icon>
                <span
                  v-if="
                    $vuetify.breakpoint.smAndUp && item.children.length === 0
                  "
                  >продукт</span
                >
              </v-btn>
            </div>
          </div>
        </template>
      </v-treeview>
    </v-card>
    <ErrorMessageWrapper ref="error_wrapper" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import ProductForm from "@/components/ProductForm.vue";
import { CATEGORIES } from "@/utils/constants";
import ErrorMessageWrapper from "@/components/ErrorMessageWrapper.vue";

const tus = require("tus-js-client");
const VUE_APP_SERVER_URI_UPLOADER_SERVICE =
  process.env.VUE_APP_SERVER_URI_UPLOADER_SERVICE ||
  "http://localhost:3000/api/upload";

export default {
  name: "AdminProductCategoriesView",
  components: { ErrorMessageWrapper },
  data() {
    return {
      saving: false,
      upload_progress: 0,
      CATEGORIES,
    };
  },
  methods: {
    ...mapActions("modals", ["showModalForm"]),
    ...mapActions("products", ["create_product"]),
    createProduct(category_id) {
      this.saving = false;
      this.showModalForm({
        component: ProductForm,
        title: "Создать новый продукт",
        desc: ``,
      }).then(async (payload) => {
        this.saving = true;
        this.$nextTick(async () => {
          this.$store.commit("setStopFlag", false);

          const file = payload.file;

          var upload = new tus.Upload(file, {
            endpoint: VUE_APP_SERVER_URI_UPLOADER_SERVICE,
            retryDelays: [0, 3000, 5000, 10000, 20000],
            chunkSize: 10000000,
            metadata: {
              filename: file.name,
              filetype: file.type,
            },
            onError: function (error) {
              console.log("Причина ошибки загрузки: " + error);
            },
            onProgress: (bytesUploaded, bytesTotal) => {
              this.upload_progress = (
                (bytesUploaded / bytesTotal) *
                100
              ).toFixed(2);
            },
            onSuccess: async () => {
              //Если видео файл успешно загружен, то создаётся продукт
              delete payload.file;
              this.create_product({
                ...payload,
                category_id: category_id,
                kinescope_id: new URLSearchParams(
                  new URL(upload.url).search
                ).get("id"),
              })
                .then(() => {
                  console.log(payload);
                })
                .catch((ex) => {
                  this.$refs.error_wrapper.showErrorMessage(
                    "Ошибка",
                    `При загрузке продукта произошла ошибка.`
                  );
                  console.log(ex);
                });
            },
          });

          upload.findPreviousUploads().then(function (previousUploads) {
            if (previousUploads.length) {
              upload.resumeFromPreviousUpload(previousUploads[0]);
            }
            upload.start();
          });
        });
      });
    },
  },
};
</script>

<style scoped lang="scss">
.multiline-text {
  white-space: normal;
  word-wrap: break-word;
  width: 75%;
}
</style>
