<template>
  <div>
    <div
      class="text-h6 text-sm-h4 my-4 d-flex justify-space-between align-center"
    >
      Компании
      <v-btn
        color="#d86568"
        large
        rounded
        class="pa-5 px-sm-10 white--text"
        @click="addNewCompany"
      >
        <v-icon left>mdi-plus</v-icon>
        Добавить
      </v-btn>
    </div>
    <v-chip-group
      v-model="selection"
      active-class="primary accent-4 white--text"
      column
    >
      <v-chip @click="switchActiveOrArchive" color="white">
        <v-icon>mdi-check</v-icon> Активные
      </v-chip>
      <v-chip @click="switchActiveOrArchive" color="white">
        <v-icon>mdi-archive</v-icon> Архивные
      </v-chip>
    </v-chip-group>
    <object-grid-advanced
      :value="
        this.companies.map((company) => ({
          id: company.id,
          name: company.name,
          city: company.city,
          manager_id: company.manager_id,
          max_patients: company.max_patients,
          price_modifier: company.price_modifier,
          ...company.custom_data,
          trial: company.trial,
          trial_parsed: company.trial === true ? 'Да' : 'Нет',
        }))
      "
      :field-defs="[
        { text: 'Компания', value: 'name', width: '10%' },
        { text: 'ИНН', value: 'inn', width: '10%' },
        { text: 'ОГРН', value: 'ogrn', width: '10%' },
        { text: 'Пробный период', value: 'trial_parsed', width: '10%' },
        { text: 'Баланс', value: 'balance', width: '8%' },
        {
          text: 'Конец пробного периода',
          value: 'trial_end_time',
          width: '10%',
        },
        { text: 'E-mail', value: 'email', width: '10%' },
        { text: 'Телефон', value: 'phone', width: '10%' },
        { text: 'Город', value: 'city', width: '10%' },
      ]"
      :show-actions="{
        edit: !selection,
        delete: !selection,
        restore: selection,
      }"
      @doEditItem="editCompany"
      @doDeleteItem="deleteCompany"
      @doRestoreItem="restoreCompany"
      :instanceId="`companiesSearch_${selection ? 'archive' : 'active'}`"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ObjectGridAdvanced from "@/components/ObjectGridAdvanced.vue";
import CompanyForm from "@/components/CompanyForm.vue";
import PromptForm from "@/components/PromptForm.vue";
import moment from "moment";

export default {
  name: "AdminCompaniesView",
  components: { ObjectGridAdvanced },
  computed: {
    ...mapGetters("companies", ["companies"]),
    ...mapGetters("users", ["users"]),

    managers() {
      return this.users.filter((user) => user.role === "manager");
    },
  },
  data: () => ({
    selection: 0,
  }),
  methods: {
    ...mapActions("companies", [
      "request_companies",
      "create_company",
      "update_company",
      "delete_company",
    ]),
    ...mapActions("users", ["request_users", "delete_user"]),
    ...mapActions("modals", ["showModalForm"]),
    formatDate(date) {
      return moment(date).format("D MMMM YYYY HH:mm");
    },
    addNewCompany() {
      this.showModalForm({
        component: CompanyForm,
        title: "Создать новую компанию",
        desc: ``,
        managers: this.managers,
      }).then((payload) => {
        // Исключение ненужных полей их custom_data
        let {
          id,
          name,
          city,
          manager_id,
          max_patients,
          trial,
          ...custom_data
        } = payload;

        let createdCompany = {
          id,
          name,
          city,
          manager_id,
          max_patients,
          trial,
          custom_data: { ...custom_data },
        };
        this.create_company(createdCompany).then(() => {
          this.request_companies();
        });
      });
    },
    editCompany(company) {
      this.showModalForm({
        component: CompanyForm,
        title: `Компания ${company.id}`,
        desc: ``,
        value: {
          ...company,
          manager_id: this.managers.find(
            (manager) => manager.id === company.manager_id
          )?.id,
        },
        managers: this.managers,
      }).then((payload) => {
        // Исключение ненужных полей из custom_data
        let {
          id,
          name,
          city,
          manager_id,
          max_patients,
          price_modifier,
          trial,
          ...custom_data
        } = payload;

        let updatedCompany = {
          id,
          name,
          city,
          manager_id,
          max_patients,
          price_modifier,
          trial,
          custom_data: { ...custom_data },
        };
        this.update_company(updatedCompany).then(() => {
          this.request_companies();
        });
      });
    },
    deleteCompany(item) {
      this.showModalForm({
        component: PromptForm,
        title: `Вы уверены что хотите удалить компанию ${item.name}?`,
        desc: `Подтвердите действие`,
        value: item,
      }).then(() => {
        this.users.forEach((user) => {
          if (user.Company && user.Company.id === item.id) {
            this.delete_user(user);
          }
        });
        this.delete_company(item).then(() => {
          this.request_companies();
          this.request_users();
        });
      });
    },
    restoreCompany(item) {
      this.showModalForm({
        component: PromptForm,
        title: `Вы уверены, что хотите восстановить компанию ${item.name}?`,
        desc: `Подтвердите действие`,
        value: item,
      }).then(() => {
        const updatedCompany = { ...item, active: true };
        this.update_company(updatedCompany).then(() => {
          this.request_companies("archive=1");
        });
      });
    },
    switchActiveOrArchive() {
      // check on next tick
      this.$nextTick(() => {
        this.request_companies(this.selection ? "archive=1" : "");
      });
    },
  },
  beforeMount() {
    this.request_companies();
    this.request_users();
  },
  mounted() {
    moment.locale("ru");
  },
};
</script>

<style scoped lang="scss"></style>
