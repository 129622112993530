<template>
  <div>
    <div
      class="text-h6 text-sm-h4 my-4 d-flex justify-space-between align-center"
    >
      B2C курсы
    </div>
    <v-chip-group
      v-model="selection"
      active-class="primary accent-4 white--text"
      column
    >
      <v-chip @click="switchActiveOrArchive" color="white">
        <v-icon>mdi-check</v-icon>
        Активные
      </v-chip>
      <v-chip @click="switchActiveOrArchive" color="white">
        <v-icon>mdi-archive</v-icon>
        Архивные
      </v-chip>
    </v-chip-group>
    <v-switch
      class="mb-4"
      v-model="filterShowOnlyPaid"
      label="Оплаченные"
      hide-details
      inset
    />
    <v-container fluid class="mx-0 px-0 my-0 py-0">
      <div
        class="pa-3 pa-sm-6"
        style="
          background-color: white;
          box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
        "
      >
        <v-row align="center">
          <v-col cols="3">
            <v-text-field
              v-model="searchByOrderNumber"
              label="Номер заказа"
              append-icon="mdi-magnify"
              class="mb-4"
              flat
              solo
              style="border: 1px solid #23919e; border-radius: 6px"
              single-line
              hide-details
              @input="debouncedSearch"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="searchByCustomerName"
              label="ФИО"
              append-icon="mdi-magnify"
              class="mb-4"
              flat
              solo
              style="border: 1px solid #23919e; border-radius: 6px"
              single-line
              hide-details
              @input="debouncedSearch"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="searchByEmail"
              label="Электронная почта"
              append-icon="mdi-magnify"
              class="mb-4"
              flat
              solo
              style="border: 1px solid #23919e; border-radius: 6px"
              single-line
              hide-details
              @input="debouncedSearch"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateRange"
                  label="Период"
                  append-outer-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  @click:append-outer="menu = true"
                  class="mb-4"
                  flat
                  solo
                  style="
                    border: 1px solid #23919e;
                    border-radius: 6px;
                    padding-right: 12px;
                  "
                  single-line
                  hide-details
                ></v-text-field>
              </template>
              <v-date-picker v-model="dates" range scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false"
                  >Закрыть</v-btn
                >
                <v-btn text color="primary" @click="onDateSelected"
                  >Поиск</v-btn
                >
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-data-table
          height="300px"
          :headers="headers"
          :items="orders.filter(orderFilter)"
          :loading="loading"
          item-key="order_number"
          :mobile-breakpoint="0"
          class="table dense-table"
          hide-default-footer
          disable-pagination
          fixed-header
          dense
          multi-sort
          loading-text="Загрузка... Пожалуйста, подождите"
        >
          <template slot="item.actions" slot-scope="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="copyCourseLink(item)"
                  color="primary"
                >
                  <v-icon small>mdi-content-copy</v-icon>
                </v-btn>
              </template>
              <span>Скопировать ссылку на курс</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="resetSessionTimer(item)"
                  color="primary"
                >
                  <v-icon small>mdi-timer-off</v-icon>
                </v-btn>
              </template>
              <span>Сбросить таймаут сессии</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="resetViewProgress(item)"
                  color="primary"
                >
                  <v-icon small>mdi-play-circle-outline</v-icon>
                </v-btn>
              </template>
              <span>Сбросить прогресс просмотра</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </div>
    </v-container>
    <v-snackbar v-model="showPopup" :color="popupColor" timeout="3000">
      {{ popupMessage }}
    </v-snackbar>
  </div>
</template>

<script>
import ApiService from "@/api/ApiService";
import moment from "moment";

export default {
  name: "B2CView",
  data() {
    return {
      dates: [
        // moment().subtract(30, "days").format("YYYY-MM-DD"),
        // moment().format("YYYY-MM-DD"),
      ],
      headers: [
        { text: "Номер заказа", value: "order_number", width: "10%" },
        { text: "ФИО", value: "customer.name", width: "20%" },
        { text: "Электронная почта", value: "customer.email", width: "20%" },
        { text: "Промокод", value: "promo_code", width: "10%" },
        {
          text: "Текущая сессия",
          value: "order_data[0].active_session",
          width: "10%",
        },
        {
          text: "Действия",
          value: "actions",
          width: "12%",
          sortable: false,
        },
      ],
      selection: 0,
      orders: [],
      page: 1,
      loading: false,
      loadingMore: false,
      hasMore: true,
      menu: false,
      searchByEmail: "",
      searchByCustomerName: "",
      searchByOrderNumber: "",
      dateRange: "",
      searchTimeout: null,
      showPopup: false,
      popupMessage: "",
      popupColor: "",
      filterShowOnlyPaid: true,
    };
  },
  methods: {
    orderFilter(order) {
      if (this.filterShowOnlyPaid) {
        return order.order_status === "paid";
      }
      return true;
    },
    async fetchOrders(loadMore = false) {
      if (this.loading || (!this.hasMore && loadMore)) {
        return;
      }

      this.loading = true;

      try {
        if (this.searchByOrderNumber) {
          const active = this.selection == 0 ? true : false;
          const response = await ApiService.get(
            `v1/orders/${this.searchByOrderNumber}`,
            { active }
          );

          if (response.status === 200) {
            this.orders = [response.data];
          } else {
            this.orders = [];
          }
          this.hasMore = false;
          this.page = 1;
        } else {
          const active = this.selection == 0 ? true : false;
          const response = await ApiService.get("v1/orders", {
            active,
            page: this.page,
            customerName: this.searchByCustomerName,
            customerEmail: this.searchByEmail,
            startDate: this.dates[0],
            endDate: this.dates[1],
          });
          console.log(response);
          const newOrders = response.data.data || [];
          this.orders = loadMore ? [...this.orders, ...newOrders] : newOrders;
          this.hasMore = !!response.data.has_more;
          if (this.hasMore) this.page++;
        }
      } catch (error) {
        console.error("Ошибка при загрузке заказов:", error);
      } finally {
        this.loading = false;
      }
    },
    debouncedSearch() {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.page = 1;
        this.hasMore = true;
        this.fetchOrders();
      }, 500);
    },
    switchActiveOrArchive() {
      this.page = 1;
      this.hasMore = true;
      this.$nextTick(() => {
        this.fetchOrders();
      });
    },
    copyCourseLink(order) {
      const link = order.order_data[0]?.link;
      if (!link) {
        this.popupMessage =
          "Ссылка не скопирована! Курс не оплачен или не активен. Обратитесь к администратору.";
        this.popupColor = "error";
        this.showPopup = true;
        return;
      }

      navigator.clipboard
        .writeText(link)
        .then(() => {
          this.popupMessage = "Ссылка успешно скопирована!";
          this.popupColor = "success";
          this.showPopup = true;
        })
        .catch((err) => {
          this.popupMessage =
            "Ошибка копирования ссылки! Обратитесь к администратору.";
          this.popupColor = "error";
          this.showPopup = true;
          console.error(err);
        });
    },
    async resetSessionTimer(order) {
      try {
        await ApiService.post(
          `v1/orders/${order.order_number}/reset_session_timer`
        );
        this.popupMessage = "Таймаут сессии успешно сброшен!";
        this.popupColor = "success";
        this.showPopup = true;
      } catch (err) {
        this.popupMessage =
          "Ошибка сброса таймаута сессии! Обратитесь к администратору.";
        this.popupColor = "error";
        this.showPopup = true;
        console.error(err);
      }
    },
    async resetViewProgress(order) {
      try {
        await ApiService.post(
          `v1/orders/${order.order_number}/reset_view_progress`
        );
        this.popupMessage = "Прогресс просмотра успешно сброшен!";
        this.popupColor = "success";
        this.showPopup = true;
      } catch (err) {
        this.popupMessage =
          "Ошибка сброса прогресса просмотра! Обратитесь к администратору.";
        this.popupColor = "error";
        this.showPopup = true;
        console.error(err);
      }
    },
    handleScroll(event) {
      const container = event.target;
      if (
        container.scrollTop + container.clientHeight >=
        container.scrollHeight - 50
      ) {
        this.fetchOrders(true);
      }
    },
    onDateSelected() {
      this.menu = false;
      this.page = 1;
      this.hasMore = true;
      if (this.dates.length === 1) {
        this.dates = [
          moment(this.dates[0]).startOf("day").format("YYYY-MM-DD"),
          moment(this.dates[0]).endOf("day").format("YYYY-MM-DD"),
        ];
      } else {
        this.dates = this.sortDates(this.dates);
      }
      this.dateRange = this.dates.join(" - ");
      this.fetchOrders();
    },
    sortDates(dates) {
      return dates.slice().sort((a, b) => new Date(a) - new Date(b));
    },
  },
  mounted() {
    this.fetchOrders();
    this.$nextTick(() => {
      const container = this.$el.querySelector(".v-data-table__wrapper");
      if (container) {
        container.addEventListener("scroll", this.handleScroll);
      }
    });
  },
  beforeDestroy() {
    const container = this.$el.querySelector(".v-data-table__wrapper");
    if (container) {
      container.removeEventListener("scroll", this.handleScroll);
    }
  },
};
</script>

<style scoped></style>
