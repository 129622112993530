var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"align-start py-0 px-0",attrs:{"fluid":"","fill-height":""}},[_c('v-card',{staticStyle:{"box-shadow":"none"},attrs:{"width":"100%"}},[_c('v-card-text',[_c('v-row',_vm._l((_vm.object_fields),function(v){return _c('v-col',{key:v,attrs:{"cols":_vm.object_prefs[v].cols}},[((_vm.object_prefs[v].type || typeof _vm.new_value[v]) !== 'boolean')?[_c('div',{staticClass:"field-wrapper"},[_c('div',[_vm._v(" "+_vm._s(_vm.object_prefs[v].label)+" ")]),_c(_vm.object_prefs[v].component
                    ? _vm.object_prefs[v].component
                    : _vm.defaultComponent,_vm._b({key:v,ref:v,refInFor:true,tag:"component",staticClass:"text-field",class:{
                  invalid: _vm.getFieldProps(v)?.hasError,
                },attrs:{"flat":"","solo":"","outline":"","hide-details":true,"value":_vm.new_value[v],"type":_vm.object_prefs[v].type || typeof _vm.new_value[v],"prepend-inner-icon":_vm.object_prefs[v].icon,"disabled":!!_vm.object_prefs[v].read_only ||
                  !_vm.object_prefs[v].role_visibility?.includes(
                    _vm.new_value?.role || _vm.value?.role || ''
                  ),"items":_vm.object_prefs[v].items,"multiple":_vm.object_prefs[v].multiple,"rules":_vm.object_prefs[v].rules},on:{"input":(val) => {
                    _vm.inputHandler(v, val);
                  },"change":(newVal) => {
                    _vm.changed = true;
                    _vm.new_value = { ..._vm.new_value, [v]: newVal };
                  }}},'component',_vm.object_prefs[v].options,false)),_c('div',{staticClass:"text-field-hint"},[(!_vm.getFieldProps(v)?.hasError)?_c('div'):(
                    _vm.getFieldProps(v)?.hasError &&
                    !_vm.getFieldProps(v)?.messagesToDisplay.length
                  )?_c('div',[_vm._v(" "+_vm._s(_vm.getFieldProps(v)?.errorBucket[0])+" ")]):_c('div',[_vm._v(" "+_vm._s(_vm.$refs[v]?.[0].messagesToDisplay?.[0])+" ")])])],1)]:_vm._e()],2)}),1),_vm._l((_vm.object_switches),function(v){return _c('v-row',{key:v},[((_vm.object_prefs[v].type || typeof _vm.new_value[v]) === 'boolean')?[_c('v-switch',{key:v,attrs:{"label":_vm.object_prefs[v].label,"v-model":_vm.new_value[v],"value":_vm.new_value[v],"disabled":!_vm.object_prefs[v].role_visibility.includes(
                _vm.new_value.role || _vm.value.role || ''
              )},on:{"change":(newVal) => {
                _vm.inputHandler(v, newVal ?? false);
              }}})]:_vm._e()],2)})],2),(!_vm.no_editing)?_c('v-card-actions',[_c('v-btn',{staticClass:"white--text",staticStyle:{"background-color":"#d86568","width":"150px","height":"50px","border-radius":"50px"},attrs:{"disabled":_vm.isFieldsInvalid || _vm.button_pressed},on:{"click":_vm.doSave}},[_vm._v(" Сохранить ")]),_vm._t("default"),_c('v-spacer')],2):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }